import React from 'react'
import styled from '@emotion/styled'
import { useState } from 'react'
import mq from '../styles/mediaQueries'

import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import NavOverlay from '../components/NavOverlay'
import Layout from '../components/Layout'

const Wrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '3', '3'],
    gridColumnEnd: ['5', '9', '12', '11', '11'],
    marginTop: ['160px', '160px', '160px', '200px', '200px'],
  })};
  ${props => (props.isOverlayOpen ? 'hidden' : 'visible')};
`

function BasicPageTemplate(props) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [pixels, setPixels] = useState(0)
  return (
    <React.Fragment>
      <NavBar
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        pixels={pixels}
        setPixels={setPixels}
        theme={'day'}
      />
      <NavOverlay
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <Layout>
        <Wrapper>
          {props.children}
          <Footer />
        </Wrapper>
      </Layout>
    </React.Fragment>
  )
}

export default BasicPageTemplate
